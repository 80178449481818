<template>
  <div>
  <template v-for="route in routeInfo">
    <MenuItem v-if="route.children.length === 0" :to="getPath(route.path)" :name="route.name" :key="route._index">
<!--      <i class="iconfont icon-liebiaoyoujiantou"></i>-->
      {{route.title}}
    </MenuItem>
    <Submenu v-else :name="route.name" :key="route._index">
      <template slot="title">
<!--        <i class="iconfont icon-liebiaoyoujiantou"></i>-->
        {{route.title}}
      </template>
      <MItem :route-info="route.children" :base-path="getPath(route.path)"></MItem>
    </Submenu>
  </template>
  </div>
</template>

<script>
import path from "path";
export default {
  name: "MItem",
  props: ['routeInfo', 'basePath'],
  methods: {
    getPath (routePath){
      return path.resolve(this.basePath, routePath);
    }
  }
}
</script>

<style scoped>

</style>