<template>
  <Menu active-name="1-1" accordion theme="dark" width="auto">
    <template v-for="route in menus">
      <MenuItem v-if="route.children.length === 0" :to="route.path" :name="route.name" :key="route._index">
      <i class="iconfont" :class="route.icon"></i>
      {{ route.title }}
      </MenuItem>
      <Submenu v-else :name="route.name" :key="route._index">
        <template slot="title">
          <i class="iconfont" :class="route.icon"></i>
          {{ route.title }}
        </template>
        <AsideMenuItem :route-info="route.children" :base-path="route.path"></AsideMenuItem>
      </Submenu>
    </template>
  </Menu>
</template>

<script>
import AsideMenuItem from "@/View/aside/AsideMenuItem";
import { generateTree } from "@/utils/generateRoutes.js"
import jwt_decode from "jwt-decode";
import { get } from "@/api/http";
import _ from "lodash";
export default {
  components: {
    AsideMenuItem
  },
  name: "AsideMenu",
  data() {
    return {
      menus: []
    }
  },
  methods: {
    getRoutesInfo() {
      let token = localStorage.getItem('token')
      if (!token) {
        return
      }

      let tokenData = jwt_decode(token)
      get("/api/v1/menu", { id: tokenData.id })
        .then((resp) => {
          this.menus = _.sortBy(generateTree(resp.data), 'index');
        })
        .catch((e) => {
          return e
        })

    }
  },
  created() {
    this.getRoutesInfo()
  }
}
</script>

<style scoped></style>